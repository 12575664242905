import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import validator from 'validator'
import update from 'immutability-helper'

import './ForgotPassword.scss'
import InputField from '../../../components/Form/InputField/InputField'
import SvgLogoLogin from '../../../Icons/LogoLogin'
import branding from '../../../shared/branding'
import orbs1 from '../plus-green.png'
import orbs2 from '../plus-red.png'
import SvgCheckSuccess from '../../../Icons/CheckSuccess'
import * as Router from '../../../shared/router'
import { ForgotPasswordAPI } from '../../../api/User/ForgotPassword'
import FormHandler from '../../../factory/FormHandler'
import toastCenter, { toastMessageType } from '../../../shared/toastCenter'
import Spinner from '../../../components/Loader/Spinner/Spinner'

const ForgotPassword = () => {
  const defaultValueInput = {
    value: '',
    isEmpty: false,
    isInvalid: false,
  }

  const [email, setEmail] = useState(defaultValueInput)
  const [isSendedEmail, setIsSendedEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendEmail()
    }
  }

  const handleSendEmail = () => {
    if (validator.isEmpty(email.value) || !validator.isEmail(email.value)) {
      setEmail(
        update(email, {
          isEmpty: { $set: validator.isEmpty(email.value) },
          isInvalid: {
            $set:
              !validator.isEmpty(email.value) &&
              !validator.isEmail(email.value),
          },
        })
      )
    } else {
      const data = new FormHandler()
      data.append('email', email.value)

      setIsLoading(true)

      const onNext = (response) => {
        if (response.success) {
          setIsSendedEmail(true)
        } else {
          toastCenter.message(
            'Not Found',
            response.messages[0],
            toastMessageType.ERROR
          )
        }
      }
      const onComplete = () => {
        setIsLoading(false)
      }
      const onError = () => {
        toastCenter.messageServerError()
      }

      const forgotPasswordApi = new ForgotPasswordAPI()
      forgotPasswordApi.subscribe(data.all(), onNext, onComplete, onError)
    }
  }

  const onChange = (e) => {
    setEmail(
      update(email, {
        value: { $set: e.target.value },
        isEmpty: { $set: validator.isEmpty(e.target.value) },
        isInvalid: {
          $set:
            !validator.isEmpty(e.target.value) &&
            !validator.isEmail(e.target.value),
        },
      })
    )
  }

  return (
    <div className="forgot-password-wrapper h-100">
      <Helmet>
        <title>{branding.NAME} - Login</title>
      </Helmet>

      {isLoading && <Spinner />}

      <div className="container h-100 position-relative">
        <div className="logo">
          <NavLink to={Router.home}>
            <SvgLogoLogin />
          </NavLink>
        </div>

        <div className="orbs1">
          <img src={orbs1} alt="" />
        </div>

        <div className="orbs2">
          <img src={orbs2} alt="" />
        </div>

        <div
          className={`form-forgot-password fade ${
            isSendedEmail ? 'hide invisible' : 'show visible'
          }`}
        >
          <h1>Forgot Password</h1>
          <p>
            Enter your account's email and we'll send you an email to reset the
            password.
          </p>
          <InputField
            label="Email Address"
            placeholder="Email Address"
            type="email"
            name="username"
            id="email"
            autoComplete="username"
            isRequired
            onKeyDown={handleKeyDown}
            isInvalid={email.isInvalid}
            isEmpty={email.isEmpty}
            onChange={onChange}
            value={email.value}
          />

          <div className="d-flex flex-row justify-content-center align-items-center">
            <button
              type="button"
              className="btn btn-lg btn-darkprimary btn-block rounded-pill btn-send-email"
              onClick={handleSendEmail}
            >
              Send Email
            </button>
          </div>
        </div>

        <div
          className={`success-submit fade ${
            isSendedEmail ? 'show visible' : 'hide invisible'
          }`}
        >
          <SvgCheckSuccess />
          <h1>Reset Password</h1>
          <p className="desc">
            An email has been sent to{' '}
            <span className="font-weight-bold">{email.value}</span>
            <br />
            Click the link in the email to reset your password.
          </p>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <NavLink
              to={Router.home}
              className="btn btn-lg btn-darkprimary btn-block rounded-pill btn-send-email d-flex justify-content-center align-items-center"
            >
              Got It
            </NavLink>
          </div>
          <p className="mb-0">Didn't get the reset password link?</p>
          <button className="btn btn-link" onClick={handleSendEmail}>
            Resend it
          </button>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
