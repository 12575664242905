import React, { useContext } from 'react';

import './LoginForm.scss';
import PasswordField from '../../../components/Form/PasswordField/PasswordField';
import InputField from '../../../components/Form/InputField/InputField';
import { LoginContext } from '../../../context/loginContext';

const LoginForm = ({ isAdmin, onKeyDown = null }) => {
  const loginContext = useContext(LoginContext);

  return (
    <div className="form-field-wrapp">
      <InputField
        label={isAdmin ? 'Username' : "Email Address"}
        placeholder={isAdmin ? 'Username' : "Email Address"}
        type={isAdmin ? 'text' : "email"}
        name="username"
        id={isAdmin ? 'username' : "email"}
        autoComplete="username"
        onKeyDown={onKeyDown}
        isRequired
        isInvalid={loginContext.email.isInvalid}
        isEmpty={loginContext.email.isEmpty}
        onChange={loginContext.email.onChange}
        value={loginContext.email.value} />

      <PasswordField
        label="Password"
        formClassName="mb-2 passsword-form-group"
        id="password"
        onKeyDown={onKeyDown}
        isRequired
        maxLength={20}
        autoComplete="password"
        isInvalid={loginContext.password.isInvalid}
        isEmpty={loginContext.password.isEmpty}
        onChange={loginContext.password.onChange}
        value={loginContext.password.value} />
    </div>
  );
}

export default LoginForm;