import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import validator from 'validator'
import update from 'immutability-helper'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'

import './Auth.scss'
import * as actions from '../../store/actions/index'
import LoginForm from './LoginForm/LoginForm'
import { LoginContext } from '../../context/loginContext'
import clientLogin from '../../shared/clientLogin'
import * as Router from '../../shared/router'
import branding from '../../shared/branding'
// import SvgLogoLogin from '../../Icons/LogoLogin.png'
// import SvgLogoLogin from '../../Icons/LogoLogin.svg'

export const Auth = ({
  onAuth,
  authRedirectPath,
  isAuthenticated,
  clientId,
}) => {
  const defaultValueInput = {
    value: '',
    isEmpty: false,
    isInvalid: false,
  }

  const [email, setEmail] = useState(defaultValueInput)
  const [password, setPassword] = useState(defaultValueInput)
  const [heading, setHeading] = useState('')
  const [attempLogin, setAttempLogin] = useState(0)

  useEffect(() => {
    setHeading(`Log in to ${branding.NAME}`)
    setAttempLogin(0)
  }, [])

  const handleSubmitLogin = () => {
    let isValidField = true

    // if (!validator.isEmpty(email.value) && !validator.isEmail(email.value)) {
    //   setEmail(
    //     update(email, {
    //       isInvalid: { $set: !validator.isEmail(email.value) },
    //     })
    //   )

    //   isValidField = false
    // }
    if (validator.isEmpty(email.value)) {
      setEmail(
        update(email, {
          isEmpty: { $set: validator.isEmpty(email.value) },
        })
      )

      isValidField = false
    }

    if (validator.isEmpty(password.value)) {
      setPassword(
        update(password, {
          isEmpty: { $set: validator.isEmpty(password.value) },
        })
      )

      isValidField = false
    }

    if (isValidField) {
      onAuth(email.value, password.value, () => {
        setAttempLogin((x) => x + 1)
      })
    }
  }

  let authRedirect = null
  if (isAuthenticated) {
    authRedirect = <Redirect to={authRedirectPath} />
  }

  if (authRedirect === null && clientId && clientId !== clientLogin.CUSTOMER) {
    authRedirect = <Redirect to={Router.signinAdmin} />
  }

  const loginContextValue = {
    email: {
      value: email.value,
      onChange: (e) => {
        setEmail(
          update(email, {
            value: { $set: e.target.value },
            isEmpty: { $set: validator.isEmpty(e.target.value) },
            isInvalid: {
              $set:
                !validator.isEmpty(e.target.value) &&
                !validator.isEmail(e.target.value),
            },
          })
        )
      },
      isEmpty: email.isEmpty,
      isInvalid: email.isInvalid,
    },
    password: {
      value: password.value,
      onChange: (e) => {
        setPassword(
          update(password, {
            value: { $set: e.target.value },
            isEmpty: { $set: validator.isEmpty(e.target.value) },
            isInvalid: {
              $set:
                !validator.isEmpty(e.target.value) &&
                !validator.isLength(e.target.value, { min: 6, max: 20 }),
            },
          })
        )
      },
      isEmpty: password.isEmpty,
      isInvalid: password.isInvalid,
    },
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitLogin()
    }
  }

  return (
    <div className="Auth h-100">
      {authRedirect}
      <Helmet>
        <title>{branding.LOGIN_TITLE}</title>
        <meta
          name="description"
          content="Artworker+ delivers high quality print ready fixes and print ready artwork. get your on demand subscription graphic design services now!"
        />
        <meta
          name="keywords"
          content="login design, buy graphic design, graphic design, graphic design australia, need design, need artwork, subscription design, design service, design platform, need a logo, logo design, business identity, brand identity"
        />
      </Helmet>

      <div className="container position-relative h-100">
        <div className="header">
          <div className="logo d-flex align-items-center">
            {branding.NAME === 'Artworker+' && (
              <>
                <a
                  href={branding.DOMAIN}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: '-5px' }}
                >
                  {/* <SvgLogoLogin /> */}
                  <img
                    src="https://d2sk9uoy4ftyax.cloudfront.net/images/artworker%2Bpoweredbyreversed_2.svg"
                    height={35}
                    alt="Artworker logo"
                  />
                </a>
                <a
                  href="https://www.wherethetradebuys.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <SvgLogoLogin /> */}
                  <img
                    src="https://d2sk9uoy4ftyax.cloudfront.net/images/artworker%2Bpoweredbyreversed_1.svg"
                    height={35}
                    alt="Artworker logo"
                  />
                </a>
              </>
            )}
          </div>

          {branding.NAME === 'Artworker+' && (
            <div className="d-flex flex-row justify-content-center align-items-center ml-auto">
              <span className="text-light nobreak text-14 text-uppercase">
                Don't have an account ?
              </span>
              <a
                className="btn btn-lg btn-darkprimary btn-block rounded-pill text-light btn-register ml-3"
                href={branding.REGISTER}
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Started
              </a>
            </div>
          )}
        </div>

        <div className="orbs1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="72"
            viewBox="0 0 24 24"
          >
            <path
              fill="#E62154"
              d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"
            />
          </svg>
        </div>

        <div className="orbs2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            viewBox="0 0 24 24"
          >
            <path fill="#fff" d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
          </svg>
        </div>

        <div className="form-signin">
          <h1>{heading}</h1>
          <p>Enter your details below.</p>
          <LoginContext.Provider value={loginContextValue}>
            <LoginForm onKeyDown={handleKeyDown} />
          </LoginContext.Provider>

          <br />
          <div
            className={`text-danger text-14 ${
              attempLogin > 1 ? 'd-none' : 'd-none'
            }`}
          >
            <span>If you've forgotten your password, please click</span>
          </div>
          <NavLink to={Router.forgotPassword} className="btn btn-link">
            Forgot Password?
          </NavLink>

          <div className="d-flex flex-row justify-content-center align-items-center">
            <button
              type="button"
              className="btn btn-lg btn-darkprimary btn-block rounded-pill btn-login"
              onClick={handleSubmitLogin}
            >
              Log in
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    clientId: state.auth.clientId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, callback) =>
      dispatch(actions.auth(email, password, false, callback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
