import React, { useState, useEffect } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import { managePaymentTourGuideService } from '../../services/tourGuideService/managePaymentTourGuideService'
import { tourGuideAction } from '../../shared/tourGuideAction'
import { userDetailService } from '../../services/userDetailService/userDetailService'
import { dummyPaymentMethodTourGuideService } from '../../services/tourGuideService/dummyTourGuideService'
import { siteName } from '../../shared/constant'

const ManagePaymentTourGuide = ({ run }) => {
  const [autoRun, setAutoRun] = useState(false)
  const [steps] = useState([
    {
      target: '.manage-payment-tour',
      title: 'Manage Payment Method button',
      content:
        'Click on the Manage Payment Method button to show the Manage Payment Method screen.',
      disableBeacon: true,
      type: 'hover',
      isFixed: true,
      spotlightPadding: 0,
      styles: {
        spotlight: {
          border: '1px solid #36465E',
        },
      },
    },
    {
      target: '.payment-method-box',
      title: 'Payment Method List section',
      content:
        'The section will show you the list your payment method(s). Here, you can view, edit, and delete your Payment Method.',
      disableBeacon: true,
      type: 'hover',
      isFixed: true,
      spotlightPadding: 0,
      styles: {
        spotlight: {
          borderRadius: '10px',
        },
      },
    },
    {
      target: '.card-payment-method-default',
      title: 'Active Payment Method',
      content: `Is the payment method that is currently active to use for each transaction in the ${siteName} system. An Active payment needs to be deactivated before it can be deleted.`,
      disableBeacon: true,
      type: 'hover',
      isFixed: true,
      spotlightPadding: 0,
      styles: {
        spotlight: {
          borderRadius: '10px',
        },
      },
    },
    {
      target: '.card-payment-method-other',
      title: 'Saved Payment Method',
      content:
        'This is your preferred saved payment method. To save a payment method set as Active. You can delete a saved payment method at any time.',
      disableBeacon: true,
      type: 'hover',
      isFixed: true,
      spotlightPadding: 0,
      styles: {
        spotlight: {
          borderRadius: '10px',
        },
      },
    },
    {
      target: '.add-new-payment-method',
      title: 'Add New Payment Method button',
      content:
        'Click on the Add New Manage Payment Method button to show the Add New Payment Method screen.',
      disableBeacon: true,
      type: 'hover',
      isFixed: true,
      spotlightPadding: 0,
      lastStep: true,
    },
  ])

  const customStyles = {
    overlay: {
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    buttonClose: {
      display: 'none',
    },
  }

  const handleJoyrideCallback = (data) => {
    const { status, lifecycle } = data

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
      lifecycle === 'complete'
    ) {
      tourGuideAction('managePayment', 'false', () => {
        userDetailService.emitReload(true)
        dummyPaymentMethodTourGuideService.emitReload(false)
      })
    }
  }

  useEffect(() => {
    const subscription = managePaymentTourGuideService
      .reload()
      .subscribe((value) => {
        if (value && run) {
          setAutoRun(run)
          dummyPaymentMethodTourGuideService.emitReload(true)
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [run])

  return (
    <div className="tour-guide-manage-payment">
      <Joyride
        callback={handleJoyrideCallback}
        run={autoRun}
        steps={steps}
        styles={customStyles}
        floaterProps={{ disableAnimation: true }}
        showSkipButton={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          skip: 'Skip',
        }}
        continuous={true}
      />
    </div>
  )
}

export default ManagePaymentTourGuide
