import React from 'react'
import PropTypes from 'prop-types'

import './AppLoader.scss'

import AppApiLoader from '../AppApiLoader/AppApiLoader'
import Spinner from '../Spinner/Spinner'
import clientLogin from '../../../shared/clientLogin'

function AppLoader({ type = 'AppApiLoader', loading = false }) {
  const clientId = localStorage.getItem('clientId')
  return type === 'AppApiLoader' ||
    (clientId && clientId === clientLogin.ADMIN) ? (
    <AppApiLoader isLoading={loading} className="client-app-loader" />
  ) : (
    loading && (
      <div className="client-app-loader">
        <Spinner />
      </div>
    )
  )
}

AppLoader.propType = {
  type: PropTypes.string,
  loading: PropTypes.bool,
}

export default AppLoader
