import React from 'react'
import BrandingLogo from '../../Icons/Logo'
import LogoApp from '../../Icons/LogoApp'

function Logo({ width }) {
  const clientId = localStorage.getItem('clientId')

  return clientId === 'user' ? (
    <LogoApp width={width} />
  ) : (
    <BrandingLogo width={width} />
  )
}

export default Logo
