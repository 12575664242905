import React from 'react'
import PropTypes from 'prop-types'

import './LoaderComponent.scss'

import AppApiLoader from '../AppApiLoader/AppApiLoader'
import Spinner from '../Spinner/Spinner'
import clientLogin from '../../../shared/clientLogin'

function LoaderComponent({ type = 'Spinner', loading = false }) {
  const clientId = localStorage.getItem('clientId')
  return type === 'AppApiLoader' ||
    (clientId && clientId === clientLogin.ADMIN) ? (
    <div className="loader-component-loader">
      <AppApiLoader isLoading={loading} />
    </div>
  ) : (
    loading && (
      <div className="loader-component-loader">
        <Spinner />
      </div>
    )
  )
}

LoaderComponent.propType = {
  type: PropTypes.string,
  loading: PropTypes.bool,
}

export default LoaderComponent
