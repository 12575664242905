import { useEffect } from 'react'
import userRole from '../shared/userRole'

function isInternalStaff(user) {
  return (
    user &&
    user.role &&
    (user.role === userRole.CREATIVE_MANAGER ||
      user.role === userRole.MANAGER ||
      user.role === userRole.PRODUCTION_MANAGER ||
      user.role === userRole.OPERATOR)
  )
}

function useLiveChat(user) {
  useEffect(() => {
    if (!isInternalStaff(user) && process.env.NODE_ENV === 'production') {
      // const script = document.createElement('script')
      // var inlineCode = document.createTextNode(
      //   '!(function(e) {var basehref = "https://teamwork.precisionproco.co.uk",token = "93401377-b4aa-4972-8da0-4862e4bfdec7";window.deskcontactwidget = {};var r=e.getElementsByTagName("script")[0],c=e.createElement("script");c.type="text/javascript",c.async=!0,c.src=basehref+"/support/v1/contact/main.js?token="+token,r.parentNode.insertBefore(c,r),window.addEventListener("message",function(e){var t=e.data[0],a=e.data[1];switch(t){case"setContactFormHeight":document.getElementById("deskcontactwidgetframe").height=Math.min(a, window.window.innerHeight - 75)}},!1);})(document);'
      // )
      // script.appendChild(inlineCode)
      // document.body.appendChild(script)
      // return () => {}
    }
  }, [user])
}

export default useLiveChat
