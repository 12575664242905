import React from 'react'

const SvgLogoFull = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 317.48 51.02"
    {...props}
    style={{ padding: '10px 0' }}
  >
    <rect width="266.46" height="51.02" fill="#36445f" />
    <path
      d="M33.37,34.42H22.73l-1.3,3.88H14.25l9.64-25.61h8.29L41.85,38.3H34.68Zm-8.91-5.57h7.18L28,18.45Zm50-10.4H67.27V12.69H88.19v5.76H81.05V38.3h-6.6ZM53.72,29.62H50.34V38.3H43.73V12.69H56.56c5.68,0,8.91,3.76,8.91,8.52a7.76,7.76,0,0,1-5.11,7.68l5.22,9.41H58Zm1.84-11.37H50.34V24h5.22a2.9,2.9,0,1,0,0-5.76Zm52.6,4-3.88,16h-7L90,12.69h7.41l3.88,17.08,4.38-17.08h5.06l4.38,17.08,3.84-17.08h7.41L119,38.3h-7Zm31.9-10c7.84,0,13.67,5.45,13.67,13.24s-5.83,13.25-13.67,13.25-13.7-5.45-13.7-13.25S132.23,12.27,140.06,12.27Zm0,5.83c-4.26,0-7,3.23-7,7.41s2.72,7.41,7,7.41S147,29.66,147,25.51,144.29,18.1,140.06,18.1Zm27.18,11.52h-3.37V38.3h-6.61V12.69h12.83c5.68,0,8.9,3.76,8.9,8.52a7.76,7.76,0,0,1-5.1,7.68l5.22,9.41h-7.57Zm1.85-11.37h-5.22V24h5.22a2.89,2.89,0,1,0,0-5.76ZM191,28.66l-1.73,2.15V38.3h-6.6V12.69h6.6V23.21l7.87-10.52h8.14l-9.94,12L205.79,38.3h-8.1Zm56,.23a7.76,7.76,0,0,0,5.1-7.68c0-4.76-3.22-8.52-8.9-8.52H230.36v5.56h9.29V16.14l8,4.21-8,4.47V22.71h-9.29V38.3H237V29.62h3.38l4.3,8.68h7.57Zm-28.88,3.84v2.11l-8-4.21,8-4.46v2.11h8.41V22.71H214.35V18.25h12.17V12.69H207.75V38.3h18.77V32.73Z"
      fill="#fff"
    />
    <rect x="266.46" width="51.02" height="51.02" fill="#e62455" />
    <path
      d="M295.11,38.29h-6.28v-10H280V22.42h8.84V12.73h6.28v9.69h8.83v5.83h-8.83Z"
      fill="#67c1be"
    />
  </svg>
)

export default SvgLogoFull
