import React, { useEffect } from 'react'
import clientLogin from './shared/clientLogin'

export const Theme = ({ isAuthenticated }) => {
  useEffect(() => {
    const clientId = localStorage.getItem('clientId')
    if (clientId === clientLogin.ADMIN) {
      document.body.classList.add('app-theme')
    } else {
      document.body.classList.remove('app-theme')
    }
  }, [isAuthenticated])

  return <div></div>
}
