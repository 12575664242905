import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  token: null,
  userId: null,
  clientId: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  role: '',
}

const authStart = (state) => {
  return updateObject(state, { error: null, loading: true })
}

const authStop = (state) => {
  return updateObject(state, { error: null, loading: false })
}

const authProco = (state) => {
  return updateObject(state, {
    error: null,
    loading: false,
    role: 'procoNewUser',
  })
}
const authProcoResend = (state) => {
  return updateObject(state, {
    error: null,
    loading: false,
    role: 'procoNewUser',
  })
}
const authProcoExist = (state) => {
  return updateObject(state, {
    error: null,
    loading: false,
    role: 'procoExistingUser',
  })
}
const authProcoActivated = (state, action) => {
  return updateObject(state, {
    userId: action.email,
    error: null,
    loading: false,
    role: 'procoActivatedUser',
  })
}
const authProcoLoggedin = (state) => {
  return updateObject(state, {
    error: null,
    loading: false,
    role: 'procoLoggedin',
  })
}
const authProcoNotExist = (state) => {
  return updateObject(state, {
    error: null,
    loading: false,
    role: 'procoNotExistingUser',
  })
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    clientId: action.clientId,
    error: null,
    loading: false,
    role: action.role,
  })
}

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  })
}

const authLogout = (state) => {
  return updateObject(state, {
    token: null,
    userId: null,
    role: '',
    loading: false,
  })
}

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state)
    case actionTypes.AUTH_STOP:
      return authStop(state)
    case actionTypes.AUTH_PROCO:
      return authProco(state)
    case actionTypes.AUTH_PROCO_RESEND:
      return authProcoResend(state)
    case actionTypes.AUTH_PROCO_EXIST:
      return authProcoExist(state)
    case actionTypes.AUTH_PROCO_ACTIVATED:
      return authProcoActivated(state, action)
    case actionTypes.AUTH_PROCO_LOGGEDIN:
      return authProcoLoggedin(state)
    case actionTypes.AUTH_PROCO_NOT_EXIST:
      return authProcoNotExist(state)
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action)
    case actionTypes.AUTH_FAIL:
      return authFail(state, action)
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state)
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action)
    default:
      return state
  }
}

export default reducer
